<template>
  <section>
    <fetch-loading label="You are being redirected to Ping, please wait ..." />
  </section>
</template>
<script setup lang="ts">
import { useAuth } from '#auth/runtime/composables'

onMounted(async () => {
  const $auth = useAuth()

  if (!$auth.loggedIn) {
    await $auth.loginWith('ping')
  } else {
    await navigateTo('/')
  }
})
</script>
